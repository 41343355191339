import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import {MutableRefObject, ReactNode, useEffect, useRef} from 'react'
import Player from 'video.js/dist/types/player'
import 'videojs-contrib-quality-levels'
import 'videojs-youtube'

interface Props {
    playerRef: MutableRefObject<Player | undefined>
    onReady: () => void
    children: ReactNode
}

export const RawPlayer = ({ children, playerRef, onReady }: Props) => {
    const videoNode = useRef<HTMLVideoElement>(null)
    
    useEffect(() => {
        if (!videoNode.current) return
        
        playerRef.current = videojs(videoNode.current, {
            // disablePictureInPicture: true,
            enableDocumentPictureInPicture: true,
        }, () => {
            onReady()
        })
        
        return () => {
            // if (playerRef.current) playerRef.current.dispose()
        }
    }, [])

    return (
        <div data-vjs-player={true}>
            <video
                className='video-js'
                ref={videoNode}
                data-setup='{ "youtube": { "customVars": { "wmode": "transparent", "ytControls": 2, "showinfo": 0, "iv_load_policy": 3, "cc_load_policy":3, "rel": 0 } } }'
            />
            {children}
        </div>
    )
}